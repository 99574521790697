import React from 'react';
import PortableText from 'react-portable-text';

import { Typography, Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { PlanCard } from './PlanCard';
import { ListItem } from '../../CustomStyles/Typography';

const useStyles = makeStyles((theme) => ({
	heading: {
		padding: '2rem 0 4rem 0',
		textAlign: 'center',
	},
	subheader: {
		lineHeight: '32px',
	},
}));

export const Plans = ({ plans }) => {
	const classes = useStyles();
	const { header, subheader, _rawDetails, planCards } = plans;
	return (
		<Container style={{ paddingBottom: '4rem' }}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignContent='center'
				spacing={2}
				className={classes.heading}>
				<Grid item>
					<Typography variant='h2' color='primary'>
						{header}
					</Typography>
				</Grid>

				<Grid item xs={12} md={8}>
					<Typography body='body1' className={classes.subheader}>
						{subheader}
					</Typography>
				</Grid>
			</Grid>
			<Grid
				container
				justifyContent='space-between'
				// spacing={2}
				style={{ paddingBottom: '2rem' }}>
				{planCards.map((card, index) => (
					<PlanCard key={index} card={card} colIndex={index} />
				))}
			</Grid>
			<PortableText
				content={_rawDetails}
				serializers={{
					normal: ({ children }) => (
						<Grid item>
							<Typography
								variant='body1'
								style={{
									color: '#4B5B69',
									fontFamily: 'Roboto',
								}}>
								{children}
							</Typography>
						</Grid>
					),
					li: ({ children }) => (
						<Grid item>
							<ListItem>
								<li>{children}</li>
							</ListItem>
						</Grid>
					),
				}}
			/>
		</Container>
	);
};
