import React, { useRef, useContext, lazy, Suspense } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import FormModalContext from '../context/FormModalContext';
import { SEO } from '../components/SEO';
import { CustomerLogos } from '../components/CustomerLogos';
import { WhyRouteManagerBody } from '../components/WhyRouteManager/WhyRouteManagerBody';
import { Testimonials } from '../components/Testimonials';
import { Hero } from '../components/General/Hero';
import { SMSPricingBody } from '../components/SMSPricing/SMSPricingBody';

// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	formBackground: {
		background: theme.workwaveBlue,
		paddingBottom: '2rem',
		marginTop: '-7rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-9rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-10rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-12rem',
		},
	},

	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	testimonialBackground: {
		backgroundSize: 'cover',
		height: '600px',
		width: '99vw',
		[theme.breakpoints.down('md')]: {
			width: '100vw',
			height: '500px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '400px',
		},
	},
	testimonialContainer: {
		marginTop: '-35rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-30rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-25rem',
		},
	},
	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '16rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
}));

const SMSPricingPage = ({ data, location }) => {
	const smsPricing = data.smsPricing.edges[0].node;
	const classes = useStyles();
	const inputRef = useRef();
	const formRef = useRef(null);

	//stuff for modal and form
	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	const handleInputScroll = (e) => {
		e.preventDefault();
		inputRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const med = useMediaQuery('(max-width: 960px)');
	const {
		metaTitle,
		metaDescription,
		hero,
		customerLogos,
		customerLogosHeader,
		formBgImage,
		marketoId,
		pardotUrl,
		contactForm,
		resourceTitle,
		resources,
		_rawResourceBody,
		testimonial,
		testimonialTitle,
		heroImage,
		intro,
		smsCenterWave,
		communicationPlans,
		_rawGuidelines,
		testimonialBackground,
	} = smsPricing;
	//Think I want to clean and refactor this into smaller components for readability.

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero
				hero={hero}
				handleInputScroll={handleInputScroll}
				waveHeight='150'
				isFeature
				sms
			/>
			<SMSPricingBody
				intro={intro}
				smsCenterWave={smsCenterWave}
				communicationPlans={communicationPlans}
				guidelines={_rawGuidelines}
			/>
			{/* <div
				className={classes.testimonialBackground}
				style={{
					backgroundImage: `url(${testimonialBackground?.asset.gatsbyImageData.images.fallback.src})`,
				}}
			/>
			<WaveUpSVG fill='#FFF' />
			<div className={classes.testimonialContainer}>
				<Testimonials
					header={testimonialTitle}
					testimonials={testimonial}
					formRef={formRef}
					background
				/>
			</div>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>
			<WaveDownSVG fill='#FFFFFF' />

			<div
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}
				className={classes.formCont}> */}
			{formModalOpen ? (
				<Form
					formId={marketoId}
					pardotUrl='https://go.route-manager.com/l/1042281/2023-10-17/65r932'
					contactForm={contactForm}
					noForm
					privacy
					location={location}
				/>
			) : null}
			{/* </div>
			<WaveUpSVG fill='#FFFFFF' />
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container> */}
			<WaveUpSVG fill='#f4f8ff' />
		</>
	);
};

export const query = graphql`
	query SMSPricingPageQuery {
		smsPricing: allSanitySmsPricing {
			edges {
				node {
					title
					metaTitle
					metaDescription
					hero {
						_rawContent
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					intro {
						header
						subheader
						introCards {
							title
							icon
							cardAccentColor {
								hexValue
							}
							textBubble {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							cellImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
						}
					}
					smsCenterWave {
						header
						subheader
						centerWaveImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						centerWaveBackground {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					communicationPlans {
						header
						subheader
						planCards {
							header
							figures
						}
						_rawDetails
					}
					_rawGuidelines
					testimonialTitle
					testimonial {
						title
						header
						testimonialText
						nameAndTitle
						company
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					testimonialBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					customerLogosHeader
					customerLogos {
						title
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					contactForm {
						header
						privacyPolicy
					}
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					marketoId
					pardotUrl
					resourceTitle
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
				}
			}
		}
	}
`;

export default SMSPricingPage;
