import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '1.3rem',
		fontWeight: 'bold',
		color: theme.workwaveBlue,
		textAlign: 'center',
		lineHeight: '1',
		margin: '0 auto',
		position: 'absolute',
		padding: '0 5px',
		left: '50px',
		right: '0',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem',
		},
	},
	card: {
		// border: '1px solid #ECECEE',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10)',
		borderRadius: '8px',
		background: theme.white,
		width: '100%',
		transition: 'transform 0.25s, box-shadow 0.75s',
		margin: '0 auto',
		position: 'relative',
		border: '2px solid #E5EAF4',
		paddingRight: '30px',
		marginBottom: '1rem',
		'&:last-of-type': {
			paddingRight: '0px',
		},
	},
	cardGrid: {
		marginBottom: '30px',
		paddingRight: '30px',
		'&:last-of-type': {
			paddingRight: '0px',
		},
	},
	cardMargin: {
		marginBottom: '26px',
	},
}));

export const Card = ({ card }) => {
	const classes = useStyles();
	const { icon, title, cardAccentColor, textBubble, cellImage } = card;
	return (
		<Grid
			item
			xs={12}
			sm={6}
			md={3}
			container
			direction='column'
			alignItems='center'
			justifyContent='space-evenly'>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				spacing={2}>
				<div className={classes.card}>
					<Grid
						container
						direction='row'
						justifyContent='flex-start'
						alignItems='center'>
						<FontAwesomeIcon
							icon={['fad', icon]}
							style={{
								height: '50px',
								width: '50px',
								border: `1px solid #E5EAF4`,
								background: '#E5EAF4',
								padding: '6px',
								color: cardAccentColor?.hexValue ?? '#002D5C',
								// borderRadius: '8px 0 0 8px',
							}}
						/>
						<Typography className={classes.title}>{title}</Typography>
					</Grid>
				</div>
			</Grid>
			<GatsbyImage image={textBubble?.asset.gatsbyImageData} />
			<GatsbyImage image={cellImage?.asset.gatsbyImageData} />
		</Grid>
	);
};
