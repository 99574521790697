import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import PortableText from 'react-portable-text';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '20px',
		minHeight: '210px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		position: 'relative',
		zIndex: 1,
		[theme.breakpoints.down('md')]: {
			maxWidth: '500px',
		},
		transition: 'transform 0.25s',
	},
	bulletCont: {
		justifyContent: 'center',
		alignItems: 'center',
		paddingTop: '4rem',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'flex-start',
		},
	},
	valuePropCont: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		paddingLeft: '34px',
		paddingRight: '34px',
		minHeight: '68px',
		[theme.breakpoints.down('md')]: {
			minHeight: '48px',
			paddingLeft: 'inherit',
			paddingRight: 'inherit',
		},
	},
	icon: {
		marginTop: '4px',
		marginRight: '8px',
	},
	valueProp: {
		fontSize: '1.25rem',
		fontWeight: 700,
		//lineHeight: '1.6rem',
		color: theme.workwaveBlue,
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.4rem',
			lineHeight: 1.2,
		},
	},
}));

export const IntroBullets = ({ bullets }) => {
	const classes = useStyles();
	return (
		<Grid container direction='row' className={classes.bulletCont} spacing={3}>
			{bullets.map((bullet, index) => {
				return (
					<Grid key={index} item xs={12} sm={8} md={6} lg={4}>
						<Grid className={classes.valuePropCont}>
							<Grid>
								<FontAwesomeIcon
									icon={['fas', bullet.icon]}
									style={{
										color: bullet.accentColor.hexValue ?? '#002D5C',
									}}
									className={classes.icon}
									size='lg'
								/>
							</Grid>
							<Grid>
								<PortableText
									content={bullet._rawContent}
									serializers={{
										normal: ({ children }) => (
											<Grid item>
												<Typography
													variant='body1'
													className={classes.valueProp}>
													{children}
												</Typography>
											</Grid>
										),
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
};
