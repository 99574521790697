import React from 'react';

import { Typography, Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Card } from './Card';

const useStyles = makeStyles((theme) => ({
	heading: {
		padding: '2rem 0 4rem 0',
		textAlign: 'center',
	},
	subheader: {
		lineHeight: '32px',
	},
}));

export const Intro = ({ notifications }) => {
	const { header, subheader, introCards } = notifications;
	const classes = useStyles();
	return (
		<Container>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignContent='center'
				spacing={2}
				className={classes.heading}>
				<Grid item>
					<Typography variant='h2' color='primary'>
						{header}
					</Typography>
				</Grid>

				<Grid item xs={12} md={8}>
					<Typography body='body1' className={classes.subheader}>
						{subheader}
					</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				{introCards.map((card, index) => (
					<Card key={index} card={card} />
				))}
			</Grid>
		</Container>
	);
};
