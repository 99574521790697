import React from 'react';
import { BgImage } from 'gbimage-bridge';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, Grid, makeStyles, Typography } from '@material-ui/core';

import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';
import { ReverseWaveDownSVG } from '../WaveSVGs/ReverseWaveDownSVG';

const useStyles = makeStyles((theme) => ({
	imgCont: {
		// marginTop: '4rem 0',
		// paddingTop: '6rem',
		zIndex: -10000,
		marginTop: '-8rem',
		[theme.breakpoints.down('lg')]: {
			marginTop: '-4rem',
		},
	},
	text: {
		color: '#FFF',
		margin: '2rem 0 0 0',
		[theme.breakpoints.down('md')]: {
			margin: '0 0 2rem 0',
		},
	},
	subheader: {
		lineHeight: '32px',
		color: '#FFF',
	},
	phone: {
		maxWidth: '350px',
		// [theme.breakpoints.down('md')]: {
		// 	marginBottom: '-2rem',
		// },
		[theme.breakpoints.down('md')]: {
			marginBottom: '-2.2rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
}));

export const SMSCenterWave = ({ smsCenterWave }) => {
	const { header, subheader, centerWaveImage, centerWaveBackground } =
		smsCenterWave;
	const classes = useStyles();
	return (
		<>
			<BgImage
				className={classes.imgCont}
				image={centerWaveBackground.asset?.gatsbyImageData}>
				<ReverseWaveDownSVG fill='#FFF' />
				<Container>
					<Grid container style={{ padding: '2rem 0' }}>
						<Grid
							item
							xs={12}
							md={10}
							lg={6}
							container
							direction='column'
							className={classes.text}>
							<Typography variant='h2'>{header}</Typography>
							<Typography variant='body1' className={classes.subheader}>
								{subheader}
							</Typography>
						</Grid>
						<Grid container item lg={6} justifyContent='center'>
							<GatsbyImage
								image={centerWaveImage?.asset.gatsbyImageData}
								className={classes.phone}
							/>
						</Grid>
					</Grid>
				</Container>
			</BgImage>
			<WaveUpSVG fill='#FFF' />
		</>
	);
};
