import React from 'react';
import { Typography, Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	card: {
		borderRadius: '8px',
	},
	header1: {
		background: theme.workwaveBlue,
		width: '100%',
		color: theme.white,
		textAlign: 'center',
		padding: '.5rem 0',
		borderRadius: '8px 0 0 0',
		border: `2px solid ${theme.workwaveBlue}`,
	},
	row1: {
		width: '100%',
		// borderBottom: '2px solid #C3CAD9',
		textAlign: 'center',
		padding: '.5rem 0',
		borderLeft: '2px solid #C3CAD9',
		borderRight: '2px solid #C3CAD9',
		borderBottom: '2px solid #C3CAD9',
		'&:last-of-type': {
			borderRadius: '0 0 0 8px',
		},
	},
	header2: {
		background: theme.workwaveBlue,
		width: '100%',
		color: theme.white,
		textAlign: 'center',
		padding: '.5rem 0',
		// borderRadius: '8px 0 0 0',
		border: `2px solid ${theme.workwaveBlue}`,
	},
	row2: {
		width: '100%',
		// borderBottom: '2px solid #C3CAD9',
		textAlign: 'center',
		padding: '.5rem 0',
		// borderLeft: '2px solid #C3CAD9',
		// borderRight: '2px solid #C3CAD9',
		borderBottom: '2px solid #C3CAD9',
		// '&:last-of-type': {
		// 	borderRadius: '0 0 0 8px',
		// },
	},
	header3: {
		background: theme.workwaveBlue,
		width: '100%',
		color: theme.white,
		textAlign: 'center',
		padding: '.5rem 0',
		borderRadius: '0 8px 0 0',
		border: `2px solid ${theme.workwaveBlue}`,
	},
	row3: {
		width: '100%',
		// borderBottom: '2px solid #C3CAD9',
		textAlign: 'center',
		padding: '.5rem 0',
		borderLeft: '2px solid #C3CAD9',
		borderRight: '2px solid #C3CAD9',
		borderBottom: '2px solid #C3CAD9',
		'&:last-of-type': {
			borderRadius: '0 0 8px 0',
		},
	},
	headerText: {
		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem',
			minHeight: '50px',
			padding: '0 15px',
			display: 'flex',
			alignItems: 'center',
		},
		[theme.breakpoints.down('xs')]: {
			minHeight: '85px',
		},
	},
}));

export const PlanCard = ({ card, colIndex }) => {
	const classes = useStyles();
	const { header, figures } = card;
	return (
		<Grid item xs={4}>
			<Grid
				container
				direction='column'
				alignItems='center'
				justifyContent='space-between'
				className={classes.card}>
				<Grid
					item
					className={
						colIndex === 0
							? classes.header1
							: colIndex === 1
							? classes.header2
							: classes.header3
					}>
					<Typography variant='h5' className={classes.headerText}>
						{header}
					</Typography>
				</Grid>
				{figures.map((figure, index) => (
					<Grid
						item
						key={index}
						className={
							colIndex === 0
								? classes.row1
								: colIndex === 1
								? classes.row2
								: classes.row3
						}>
						<Typography variant='body1' style={{ textAlign: 'center' }}>
							{figure}
						</Typography>
					</Grid>
				))}
			</Grid>
		</Grid>
	);
};
