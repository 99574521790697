import React from 'react';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Typography, Grid, Container } from '@material-ui/core';

import { ListItem } from '../CustomStyles/Typography';

export const Guidelines = ({ guidelines }) => {
	return (
		<Container>
			<PortableText
				content={guidelines}
				serializers={{
					normal: ({ children }) => (
						<Grid item>
							<Typography
								variant='body1'
								style={{
									color: '#4B5B69',
									fontFamily: 'Roboto',
								}}>
								{children}
							</Typography>
						</Grid>
					),
					li: ({ children }) => (
						<Grid item>
							<Typography variant='body1'>
								<li style={{ listStyleType: 'none', marginBottom: '10px' }}>
									{/* <Grid container>
										<Grid item xs={1}> */}
									<FontAwesomeIcon
										icon={['fas', 'circle-check']}
										size='lg'
										style={{
											color: '#002D5C',
											marginLeft: '-8px',
											marginRight: '10px',
										}}
									/>
									{/* </Grid>
										<Grid item xs={11}> */}
									{children}
									{/* </Grid>
									</Grid> */}
								</li>
							</Typography>
						</Grid>
					),
					h2: ({ children }) => (
						<Typography variant='h2' color='primary'>
							{children}
						</Typography>
					),
					link: ({ href, children }) => {
						return (
							<a
								href={href}
								style={{
									textDecoration: 'underline',
									color: '#4B5B69',
									fontFamily: 'Roboto',
								}}
								target='_blank' aria-label="Opens in a new tab"
								rel='noreferrer'>
								{children[0]}
							</a>
						);
					},
				}}
			/>
		</Container>
	);
};
