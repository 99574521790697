import React from 'react';

import { Intro } from './Intro';
import { SMSCenterWave } from './SMSCenterWave';
import { Plans } from './Plans';
import { Guidelines } from './Guidelines';

export const SMSPricingBody = ({
	intro,
	smsCenterWave,
	communicationPlans,
	guidelines,
}) => {
	return (
		<>
			<Intro notifications={intro} />
			<SMSCenterWave smsCenterWave={smsCenterWave} />
			<Plans plans={communicationPlans} />
			<div style={{ paddingBottom: '14rem' }}>
				<Guidelines guidelines={guidelines} />
			</div>
		</>
	);
};
