import React, { useState, useEffect } from 'react';

import { Container, Grid } from '@material-ui/core';

import { Intro } from './Intro';
import { IntroLower } from './IntroLower';
import { CenterFeatures } from './CenterFeatures';
import { WaveUpSVG } from '../WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../WaveSVGs/WaveDownSVG';
import { IntroBullets } from './IntroBullets';

export const WhyRouteManagerBody = ({ whyRouteManager }) => {
	const [selected, setSelected] = useState(null);
	const { _rawUpperIntro, _rawLowerIntro, introBullets, centerSection, lowerIntroCTAText } = whyRouteManager;

	useEffect(() => {
		setSelected(centerSection.content[0]);
	}, []);

	return (
		<>
			<Container>
				<Intro intro={_rawUpperIntro} />
        <IntroBullets bullets={introBullets}  />
        <IntroLower intro={_rawLowerIntro} button={lowerIntroCTAText} />
			</Container>
			<WaveDownSVG fill='#FFF' shadow />
			<div style={{ background: '#f5f9ff', padding: '14rem 0 16rem 0' }}>
				<CenterFeatures
					centerSection={centerSection}
					setSelected={setSelected}
					selected={selected}
				/>
			</div>
			<WaveUpSVG fill='#FFF' shadow />
		</>
	);
};
