import React, {useContext} from 'react';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import FormModalContext from '../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
  intro: {
    textAlign: 'center',
    marginTop: '2rem',
		[theme.breakpoints.down('md')]: {
			textAlign: 'left',
		},
  },
	paragraph: {
    color: theme.workwaveBlue,
    fontSize: '1.875rem',
		fontWeight: 700,
		lineHeight: '40px',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.625rem',
		},
	},
  button: {
    textTransform: 'uppercase',
  }
}));

export const IntroLower = ({ intro, button }) => {
	//stuff for modal and form
	const { setFormModalOpen } = useContext(FormModalContext);

	const classes = useStyles();
	return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        className={classes.intro}>
        <Grid 
          item
          container
          direction='column'
          xs={12}
          md={8}
          justifyContent='center'
          alignItems='center'>
          <PortableText
            content={intro}
            serializers={{
              normal: ({ children }) => (
                <Typography variant='body1' className={classes.paragraph}>
                  {children}
                </Typography>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'>
        <Button
          variant='contained'
          size='large'
          color='primary'
          onClick={(e) => setFormModalOpen(true)}
          style={{
            color: 'white',
            marginTop: '2rem',
          }}>
          {button}
        </Button>
      </Grid>
    </>
	);
};
